<template>
  <OneColumnTemplate>
    <template v-slot:ColumnContent>
      <div class="PageContentWrapper">
        <!-- <Navbar
          :hideNavbarLeftLogo="true"
          :replaceNameOnCoins="true"
          :coinsValue="store?.user_coins"
          :key="'user-coins-' + store?.user_coins"
        /> -->

        <span
          :key="'OctaCoinsBalanceUpdater' + store.user_coins"
          class="OctaS_PageTitleDefault"
          >Обмен игровой валюты
          <Octocoins
            style="border-radius: 20px;
  background: linear-gradient(91deg, #1e768e 2.08%, #9652d4 98.48%);"
            :BorderColor="'transparent'"
          >
            <template v-slot:content>
              <span class="Product-Price-Text">{{ store.user_coins }}</span>
            </template>
          </Octocoins></span
        >

        <SortingWithFiltersPreset
          class="HideSortTitle"
          :title="''"
          :originalArray="store.products"
          :searchAttribures="filters.searchInProperties"
          :setNewArrayCallback="handleSearchFilterSortingObject"
          :sortMethodCallback="sortingMethods"
          :filtersButtons="filters.filtersButtons"
          :sortOptions="filters.sortingOptions"
          :callbackForFilterButton="handleFilterButton"
          :filterButtonModeServer="true"
          :setFiltersData="{
            button: filters.selectedButton,
            search: filters.selectedSearch,
            sorting: filters.selectedSorting,
          }"
          :postFiltersDataChanges="handleFilterChanges"
          :key="'SortingFiltersSearch-' + products_hash"
        />

        <div
          class="Products-Block"
          :key="'product-block' + products_filtered_hash"
        >
          <div
            v-for="(p, pindx) in store.products_filtered"
            :key="pindx + 'product-' + p.id"
            :class="{
              'Product-Card': true,
              noselect: true,
              'No-BG-IMG': p?.ProductImages?.length != 0,
            }"
          >
            <span class="Product-Title">{{ p.name }}</span>
            <span class="Product-Desc" v-if="p.desc != null">{{ p.desc }}</span>
            <span
              class="Product-More-Button"
              v-if="
                p.desc != null &&
                  p.price <= store.user_coins &&
                  p.available_amount !== 0 &&
                  (p.ProductOrdersCount < p.limited_quantity ||
                    p.limited_quantity === 0)
              "
              @click="
                () => {
                  openModal(p);
                }
              "
              >подробнее</span
            >

            <div class="Product-Bottom-Block">
              <div class="Product-Tips">
                <span
                  v-for="(tip, tindx) in getTipsArray(p)"
                  :key="tindx + 'tip' + tip.text"
                  :class="tip.classessList"
                  >{{ tip.text }}</span
                >
              </div>

              <Octocoins class="OctaCoinsStyle" :BorderColor="'transparent'">
                <template v-slot:content>
                  <span class="Product-Price-Text">{{ p.price }}</span>
                </template>
              </Octocoins>

              <span
                :class="{
                  'Product-Transfer-Button': true,
                  'Not-Available':
                    p.openSoon ||
                    p.price > store.user_coins ||
                    p.available_amount === 0 ||
                    (p?.ProductOrdersCount >= p.limited_quantity &&
                      p.limited_quantity !== 0),
                }"
                @click="
                  () => {
                    if (
                      !p.openSoon &&
                      p.price <= store.user_coins &&
                      p.available_amount !== 0 &&
                      (p?.ProductOrdersCount < p.limited_quantity ||
                        p.limited_quantity === 0)
                    ) {
                      openModal(p);
                    }
                  }
                "
                >{{
                  p?.ProductOrdersCount >= p.limited_quantity &&
                  p.limited_quantity !== 0
                    ? "Уже приобретено"
                    : "Обменять"
                }}</span
              >
            </div>

            <div
              v-if="p.ProductImages.length != 0"
              class="Product-Background-Image"
              :style="
                `
              background: url('${p.ProductImages?.[0]?.url_file}');
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;`
              "
            ></div>
          </div>
        </div>

        <span
          v-if="store.loaded && store.products?.length === 0"
          :key="'TextNoItems-' + products_hash + store.products?.length === 0"
          class="NoItemsShowed"
          >На данный момент список пуст</span
        >

        <span
          v-if="
            store.loaded &&
              store.products.length != 0 &&
              store.products.length < store.products_counter &&
              store.load_more_is_active
          "
          class="Offer-Button-Next Load-More-Objects"
          :key="
            'load-more-button' + store.products.length + store.products_counter
          "
          @click="
            () => {
              if (store.load_more_is_active) {
                loadMoreButton();
              }
            }
          "
          >Загрузить ещё</span
        >

        <NewStyledModal
          class="Styled-Modal"
          :showModal="show_modal"
          :hideModal="
            () => {
              show_modal = false;
              product_selected = null;
            }
          "
          :key="show_modal"
        >
          <template v-slot:ModalContent>
            <div class="Modal-Card-Wrapper" v-if="product_selected != null">
              <div
                v-if="product_selected.ProductImages.length != 0"
                class="Modal-Card-Image"
                :style="
                  `
              background: url('${product_selected.ProductImages?.[0]?.url_file}');
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              filter: none;
              opacity: 1;
              `
                "
              ></div>

              <div class="Product-Card Modal-Card noselect">
                <span class="Product-Title">{{ product_selected.name }}</span>

                <span class="Product-Desc" v-if="product_selected.desc != null"
                  >{{ product_selected.desc }}
                </span>

                <div
                  class="Product-Bottom-Block"
                  :style="
                    product_selected.ProductImages.length != 0
                      ? 'margin-top: auto;'
                      : ''
                  "
                >
                  <div class="Product-Tips">
                    <span
                      v-for="(tip, tindx) in getTipsArray(product_selected)"
                      :key="tindx + 'tip' + tip.text"
                      :class="tip.classessList"
                      >{{ tip.text }}</span
                    >
                  </div>
                  <Octocoins
                    class="OctaCoinsStyle"
                    :BorderColor="'transparent'"
                  >
                    <template v-slot:content>
                      <span class="Product-Price-Text">{{
                        product_selected.price
                      }}</span>
                    </template>
                  </Octocoins>

                  <div class="Offer-Modal-Buttons noselect">
                    <span
                      v-if="
                        product_selected.price <= store.user_coins &&
                          product_selected.available_amount !== 0 &&
                          (product_selected.ProductOrdersCount <
                            product_selected.limited_quantity ||
                            product_selected.limited_quantity === 0)
                      "
                      :class="{
                        'Offer-Button-Next': true,
                        'Not-Available': false,
                      }"
                      @click="
                        () => {
                          show_modal = false;
                          buy_offer.show = true;
                        }
                      "
                      >Перейти к покупке</span
                    >
                    <span
                      v-else
                      :class="{
                        'Offer-Button-Next': true,
                        'Not-Available': true,
                      }"
                      >Обмен недоступен</span
                    >
                    <span
                      class="Offer-Button-Prev"
                      @click="
                        () => {
                          resetBuyOffer();
                          show_modal = false;
                          product_selected = null;
                        }
                      "
                      >Отмена</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </template>
        </NewStyledModal>

        <NewStyledModal
          class="Styled-Modal"
          :showModal="buy_offer.show"
          :hideModal="
            () => {
              resetBuyOffer();
            }
          "
          :key="buy_offer.show"
        >
          <template v-slot:ModalContent>
            <div class="Offer-Modal">
              <span class="Offer-Modal-Title">
                <template v-if="buy_offer.step === 1"
                  >Оформление обмена</template
                >
                <template v-if="buy_offer.step === 2"
                  >Подтверждение обмена</template
                >
                <template v-if="buy_offer.step === 3">Обмен завершен</template>
              </span>
              <div class="Offer-Modal-Content">
                <template v-if="buy_offer.step === 1">
                  <div class="Input-Floating-Label">
                    <input
                      :class="{
                        'Input-styled': true,
                        'Input-highligt-error':
                          buy_offer.highlight_errors &&
                          buy_offer.name.length < 2,
                      }"
                      type="text"
                      placeholder="Введите имя"
                      autocomplete="off"
                      maxlength="64"
                      v-model="buy_offer.name"
                    />
                    <span class="Input-Placeholder noselect">Ваше имя</span>
                  </div>
                  <div class="Input-Floating-Label">
                    <input
                      :class="{
                        'Input-styled': true,
                        'Input-highligt-error':
                          buy_offer.highlight_errors &&
                          buy_offer.phone_number.length < 16,
                      }"
                      type="text"
                      placeholder="Введите номер телефона"
                      autocomplete="off"
                      maxlength="16"
                      mask="+7(###)###-##-##"
                      @input="phoneMask"
                      v-model="buy_offer.phone_number"
                    />
                    <span class="Input-Placeholder noselect"
                      >Ваш номер телефона</span
                    >
                  </div>
                  <div class="Input-Floating-Label">
                    <input
                      :class="{
                        'Input-styled': true,
                        'Input-highligt-error':
                          buy_offer.highlight_errors &&
                          buy_offer.telegram.length < 2,
                      }"
                      type="text"
                      placeholder="@nickname"
                      autocomplete="off"
                      maxlength="32"
                      mask="@###############"
                      @input="telegramMask"
                      v-model="buy_offer.telegram"
                    />
                    <span class="Input-Placeholder noselect">Ваш telegram</span>
                  </div>
                </template>
                <template v-if="buy_offer.step === 2">
                  <span class="Offer-Text-Before-Confirm"
                    >Вы хотите обменять свои
                    <span class="Text-Before-Confirm-Important"
                      >{{ product_selected.price }} баллов</span
                    >
                    на
                    <span class="Text-Before-Confirm-Important">{{
                      product_selected.name
                    }}</span></span
                  >
                </template>
                <template v-if="buy_offer.step === 3">
                  <!-- <span class="Offer-Text-Before-Confirm"
                    >Поздравляем! Свои приобретения ты можешь посмотреть в
                    разделе
                    <span class="Text-Before-Confirm-Important"
                      >Инвентарь</span
                    ></span
                  > -->
                  <span class="Offer-Text-Before-Confirm"
                    >Поздравляем! Свои приобретения ты сможешь увидеть в скором
                    обновлении</span
                  >
                </template>
              </div>
              <div class="Offer-Modal-Buttons noselect">
                <template v-if="buy_offer.step === 1">
                  <span
                    :class="{
                      'Offer-Button-Next': true,
                      'Not-Available':
                        buy_offer.name.length < 2 ||
                        buy_offer.phone_number.length < 16 ||
                        buy_offer.telegram.length < 2,
                    }"
                    @click="
                      () => {
                        offerNext();
                      }
                    "
                    >К подтверждению</span
                  >
                  <span
                    class="Offer-Button-Prev"
                    @click="
                      () => {
                        resetBuyOffer();
                        show_modal = true;
                      }
                    "
                    >Назад</span
                  >
                </template>
                <template v-if="buy_offer.step === 2">
                  <div class="Offer-Modal-Buttons-Child-Wrapper">
                    <span class="Offer-Text-Balance-After">
                      баланс после обмена:
                      <span style="color: #D3CAFFB2"
                        >{{
                          store.user_coins - product_selected?.price
                        }}
                        баллов</span
                      >
                    </span>

                    <div class="Offer-Modal-Buttons noselect">
                      <span
                        :class="{
                          'Offer-Button-Next': true,
                          'Not-Available': buy_offer.offer_in_creation,
                        }"
                        @click="
                          () => {
                            offerNext();
                          }
                        "
                        >Подтвердить</span
                      >
                      <span
                        class="Offer-Button-Prev"
                        @click="
                          () => {
                            buy_offer.step = 1;
                          }
                        "
                        >Назад</span
                      >
                    </div>

                    <span
                      class="Offer-Text-Error"
                      v-if="buy_offer?.error != null"
                    >
                      {{ buy_offer?.error }}
                    </span>
                  </div>
                </template>
                <template v-if="buy_offer.step === 3">
                  <span
                    class="Offer-Button-Next"
                    @click="
                      () => {
                        initialization();
                        resetBuyOffer();
                      }
                    "
                    >Спасибо!</span
                  >

                  <!-- <span class="Offer-Button-Next">В инвентарь</span>
                  <span
                    class="Offer-Button-Prev"
                    @click="
                      () => {
                        initialization();
                        resetBuyOffer();
                      }
                    "
                    >Остаться</span
                  > -->
                </template>
              </div>
            </div>
          </template>
        </NewStyledModal>
      </div>
    </template>
  </OneColumnTemplate>
</template>

<script>
import { mapGetters } from "vuex";

import { getRegionAndUniver } from "@/api/user.js";

import {
  getStoreProductsByFilters,
  getProductsOnSiteCounter,
  buyProduct,
} from "@/api/store.js";
import { getCoinsValueForUser } from "@/api/user_coins.js";

import Navbar from "@/components/Navbar/NavbarCombined.vue";
import OneColumnTemplate from "@/components/ProfileTemplates/OneColumn.vue";
import SortingWithFiltersPreset from "@/components/CustomElements/SortingWithFiltersPreset.vue";
import Octocoins from "@/components/CustomElements/Octocoins.vue";
import NewStyledModal from "@/components/CustomElements/NewStyledModal.vue";

export default {
  name: "OctaStore",
  components: {
    Navbar,
    OneColumnTemplate,
    SortingWithFiltersPreset,
    Octocoins,
    NewStyledModal,
  },
  data() {
    return {
      show_modal: false,

      filters: {
        sortingOptions: [
          {
            text: "Сортировка по умолчанию",
            val: "default",
          },
          {
            text: "Цена - Выше",
            val: "price_higher",
          },
          {
            text: "Цена - меньше",
            val: "price_lower",
          },
        ],
        searchInProperties: ["name", "desc", "price"],
        filtersButtons: [
          {
            text: "Доступные",
            val: "available",
          },
          {
            text: "Новинки",
            val: "new",
          },
          // {
          //   text: "Рекомендуемые",
          //   val: "recommended",
          // },
          {
            text: "Недоступные",
            val: "unavailable",
          },
        ],
        selectedButton: null,
        selectedSorting: "default",
        selectedSearch: "",
      },

      store: {
        loaded: false,

        user_coins: 0,
        user_region: null,
        load_per_request: 20,
        offset: 0,

        products: [],
        products_filtered: [],
        products_counter: 0,

        load_more_is_active: true,
      },

      buy_offer: {
        show: false,
        step: 1,
        highlight_errors: false,
        offer_in_creation: false,

        error: null,

        product_id: null,
        name: "",
        phone_number: "",
        telegram: "",
      },
      product_selected: null,
    };
  },
  async created() {
    let userRegionResponse = await getRegionAndUniver(this?.user?.id);
    this.store.user_region =
      userRegionResponse.data.MapRegion?.UsersChoosedUniver?.region?.id;

    await this.initialization();
  },
  methods: {
    async initialization() {
      await this.updateCoins();

      let data = {
        user_id: this?.user?.id,
        count: this.store.load_per_request,
        offset: 0,
        selected_button: this.filters.selectedButton,
      };

      let _store_products_counter = await getProductsOnSiteCounter(data);
      this.store.products_counter = _store_products_counter.data;

      let _store_products = await getStoreProductsByFilters(data);
      //TODO: Из-за доп. фильтра на регионы, products_counter может и будет работать не корректно. В дальнейшем нужен переход на бэк
      this.store.products = _store_products.data.filter((x) => {
        if (
          x.product_region != null &&
          x.product_region != this.store.user_region
        ) {
          return false;
        }
        return true;
      });

      if (!this.store.loaded) {
        this.store.loaded = true;
      }
    },

    async updateCoins() {
      let _user_coins = await getCoinsValueForUser(this?.user?.id);
      this.store.user_coins = _user_coins.data;

      if (
        this.buy_offer.show &&
        this.product_selected != null &&
        this.buy_offer.step !== 3 &&
        _user_coins.data < this.product_selected.price
      ) {
        window.location.reload();
        return "fail";
      }

      return "success";
    },

    getTipsArray(product) {
      let array = [];

      if (product.openSoon) {
        array.push({
          text: "Скоро откроется",
          classessList: "Product-Tip Is-Recommended",
        });

        return array;
      }

      if (this.isNewProduct(product.createdAt)) {
        array.push({
          text: "Новинка",
          classessList: "Product-Tip Is-New",
        });
      }

      if (product.available_amount === 0) {
        array.push({
          text: "Товар закончился",
          classessList: "Product-Tip Not-Enough-Coins",
        });

        return array;
      }

      if (product.available_amount <= 5 && product.available_amount !== 0) {
        array.push({
          text: "Скоро закончится",
          classessList: "Product-Tip Few-Products",
        });
      }

      if (product.price > this.store.user_coins) {
        array.push({
          text: "Недостаточно баллов",
          classessList: "Product-Tip Not-Enough-Coins",
        });
      }

      if (false) {
        array.push({
          text: "Рекомендуем",
          classessList: "Product-Tip Is-Recommended",
        });
      }

      return array;
    },
    isNewProduct(product_created) {
      const specifiedDate = new Date(product_created);
      const currentDate = new Date();

      const difference = currentDate.getTime() - specifiedDate.getTime();

      return difference < 5 * 24 * 60 * 60 * 1000;
    },

    openModal(product) {
      this.product_selected = product;
      this.show_modal = true;
    },

    resetBuyOffer() {
      this.buy_offer = {
        show: false,
        step: 1,
        highlight_errors: false,
        offer_in_creation: false,

        error: null,

        product_id: null,
        name: "",
        phone_number: "",
        telegram: "",
      };
    },

    phoneMask(event) {
      let input = event.target;
      let value = input.value.replace(/\D/g, ""); // Удаляем все нецифровые символы
      let formattedValue = "";

      if (value.length > 0) {
        formattedValue = "+7(" + value.substring(1, 4);
      }
      if (value.length > 4) {
        formattedValue += ")" + value.substring(4, 7);
      }
      if (value.length > 7) {
        formattedValue += "-" + value.substring(7, 9);
      }
      if (value.length > 9) {
        formattedValue += "-" + value.substring(9, 11);
      }

      input.value = formattedValue;
      this.buy_offer.phone_number = formattedValue;
    },
    telegramMask(event) {
      let input = event.target;
      let value = input.value;
      if (value != null && value.length == 1) {
        if (value[0] === "@") {
          value = "";
        }
      } else if (value != null && value.length > 1) {
        value = value.replace("@", "");
      }
      let formattedValue = "";

      formattedValue = "@" + value;

      input.value = formattedValue;
      this.buy_offer.telegram = formattedValue;
    },

    async offerNext() {
      if (this.buy_offer.step === 1) {
        if (
          this.buy_offer.name.length < 2 ||
          this.buy_offer.phone_number.length < 16 ||
          this.buy_offer.telegram.length < 2
        ) {
          this.buy_offer.highlight_errors = true;
          return 0;
        }

        let coinsIsVaild = await this.updateCoins();
        if (coinsIsVaild === "fail") {
          return 0;
        }

        this.buy_offer.highlight_errors = false;
        this.buy_offer.step = 2;
        return 0;
      }
      if (this.buy_offer.step === 2) {
        if (this.buy_offer.offer_in_creation) {
          return 0;
        }
        this.buy_offer.offer_in_creation = true;

        let coinsIsVaild = await this.updateCoins();
        if (coinsIsVaild === "fail") {
          return 0;
        }

        let _buy_transaction = await buyProduct({
          user_id: this.user.id,
          product_id: this.product_selected.id,
          name: this.buy_offer.name,
          phone: this.buy_offer.phone_number,
          tg: this.buy_offer.telegram,
        });

        if (_buy_transaction.data.status === "fail") {
          this.buy_offer.error = _buy_transaction.data.text;
          return 0;
        }

        this.buy_offer.step = 3;
        this.buy_offer.offer_in_creation = false;

        await this.initialization();

        return 0;
      }
    },

    sortingMethods(sort_name, array) {
      if (sort_name === "price_higher") {
        array = array.sort((a, b) => {
          return b.price - a.price;
        });

        return array;
      }

      if (sort_name === "price_lower") {
        array = array.sort((a, b) => {
          return a.price - b.price;
        });

        return array;
      }

      return array;
    },
    handleSearchFilterSortingObject(Array) {
      this.store.products_filtered = Array;
    },
    handleFilterButton(val) {
      this.filters.selectedButton = val;
      this.store.offset = 0;
      this.initialization();
    },
    handleFilterChanges(obj) {
      this.filters.selectedButton = obj.button;
      this.filters.selectedSearch = obj.search;
      this.filters.selectedSorting = obj.sorting;
    },

    async loadMoreButton() {
      this.store.load_more_is_active = false;
      this.store.offset = this.store.offset + 1;

      let data = {
        user_id: this?.user?.id,
        count: this.store.load_per_request,
        offset: this.store.offset,
        selected_button: this.filters.selectedButton,
      };

      let _store_products = await getStoreProductsByFilters(data);
      this.store.products = [...this.store.products, ..._store_products.data];
      this.store.load_more_is_active = true;
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),

    products_hash() {
      let a = this.store.products.map(
        (x) =>
          `${x.id}_${x?.archieve}_${a?.ProductOrdersCount}_${a?.available_amount}_${a?.ProductOrdersCount}`
      );
      return `${this.store.products?.length}-${a.join("-")}`;
    },

    products_filtered_hash() {
      let a = this.store.products_filtered.map(
        (x) =>
          `${x.id}_${x?.archieve}_${a?.ProductOrdersCount}_${a?.available_amount}_${a?.ProductOrdersCount}`
      );
      return `${this.store.products_filtered?.length}-${a.join("-")}`;
    },
  },
};
</script>

<style scoped>
.NoItemsShowed {
  position: relative;
  display: block;

  margin-top: 50px;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 30px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #ffffffe5;
}

.Offer-Button-Next.Load-More-Objects {
  width: fit-content;
  flex-shrink: 0;

  margin: 0% auto;
}

.PageContentWrapper {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 36px;

  width: 100%;
  height: 100%;

  padding-right: 15px;

  overflow: auto;
}

.Products-Block {
  position: relative;
  display: grid;

  grid-template-columns: repeat(5, calc((100% - 24px * 4) / 5));
  gap: 24px;

  width: 100%;
  height: fit-content;
}

.Product-Card {
  position: relative;
  display: flex;

  padding: 20px;

  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;

  width: 100%;
  height: auto;

  /* aspect-ratio: 0.88 / 1; */

  border-radius: var(--o-s-global-border-radius);

  background: url("./../../assets/img/Admin/LogoBG.png");
  background-position: 70% 0%;
  background-size: 60%;
  background-repeat: no-repeat;
  background-color: #1e1b4a;

  transition-timing-function: ease-in;
  transition: 0.2s;

  overflow: hidden;
}
.Product-Card:hover {
  background-color: #27235c;
}
.Product-Card.No-BG-IMG {
  background: #1e1b4a;
  background-color: #1e1b4a;
}
.Product-Card > *:not(.Product-Background-Image) {
  z-index: 5;
}
.Product-Card:hover > .Product-Background-Image {
  opacity: 0.05;
}
.Product-Background-Image {
  position: absolute;
  display: block;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  transition-timing-function: ease-in;
  transition: 0.2s;

  border-radius: 12px;

  filter: blur(2px);
  opacity: 0.1;
  z-index: 3;
}
.Modal-Card-Image {
  position: relative;
  display: block;

  top: 0;
  left: 0;

  max-width: 500px;
  width: auto;
  height: 300px;

  aspect-ratio: 0.88 / 1;

  border-radius: 8px;

  filter: blur(2px);
  opacity: 0.1;
  z-index: 3;
}

.Product-Title {
  position: relative;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #ffffffe5;
}
.Product-Desc {
  position: relative;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;

  color: #ffffffe5;
}
.Product-More-Button {
  position: relative;
  display: block;

  cursor: pointer;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;

  color: #656bff;
}

.Product-Transfer-Button {
  position: relative;
  display: block;
  cursor: pointer;

  padding: 8px 0px;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;

  border-radius: 4px;

  color: #ffffffe5;
  background-color: #656bff;

  transition-timing-function: ease-in;
  transition: 0.2s;

  opacity: 1;
}
.Product-Transfer-Button:hover,
.Offer-Button-Next:hover {
  background-color: #656bffe5;
}
.Product-Transfer-Button.Not-Available,
.Offer-Button-Next.Not-Available {
  cursor: default;
  opacity: 0.3;
}

.Product-Bottom-Block {
  position: relative;
  display: flex;

  margin-top: auto;

  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;

  width: 100%;
  height: fit-content;
}
.Product-Price-Text {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;

  color: #ffffffe5;
}
.OctaCoinsStyle {
  gap: 4px;
  padding: 0px;
}
.OctaCoinsStyle:deep(.coinsLogo) {
  width: auto;
  height: 20px;

  aspect-ratio: 0.4705 / 1;
}

.Product-Tips {
  position: relative;
  display: flex;

  margin-bottom: 4px;

  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 4px;

  width: 100%;
  height: fit-content;
}
.Product-Tip {
  position: relative;
  display: block;

  padding: 4px 8px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;

  border-radius: 4px;
}
.Product-Tip.Not-Enough-Coins {
  color: #ed254e;
  border: 1px solid #ed254e;
  background-color: transparent;
}
.Product-Tip.Is-New {
  color: #25ed69;
  border: 1px solid #25ed6926;
  background-color: #25ed6926;
}
.Product-Tip.Is-Recommended {
  color: #e924bf;
  border: 1px solid #e924bf26;
  background-color: #e924bf26;
}
.Product-Tip.Few-Products {
  color: #f9dc5c;
  border: 1px solid #f9dc5c26;
  background-color: #f9dc5c26;
}
</style>

<style scoped>
.HideSortTitle:deep(.FiltersHeader) {
  display: none;
}

.Styled-Modal:deep(.SendRequestModal) {
  min-width: 0;
  width: fit-content;
  background: #1e1b4a;
  border-radius: var(--o-s-global-border-radius);
}

.Modal-Card-Wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;

  width: fit-content;
  height: fit-content;
}
.Product-Card.Modal-Card {
  position: relative;

  min-width: 0;
  width: 320px;
  height: auto;
  padding: 0px;

  border-radius: 0px;
  background: transparent;
  background-color: transparent;
}
.Product-Card.Modal-Card .Product-Bottom-Block {
  margin-top: 8px;
}
.Product-Card.Modal-Card .Product-Desc {
  display: block;
  color: #d3caffb2;
}

.Offer-Modal {
  position: relative;
  display: flex;

  padding-top: 12px;

  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;

  width: fit-content;
  height: fit-content;
}

.Offer-Modal-Title {
  position: relative;
  display: block;

  min-width: 320px;
  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;

  color: #ffffffe5;
}
.Offer-Modal-Content {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;

  min-width: 320px;
  width: fit-content;
  height: fit-content;
}

.Input-Floating-Label {
  position: relative;
  display: block;

  min-width: 320px;
  width: fit-content;
  height: fit-content;
}

.Input-styled {
  position: relative;
  display: block;

  padding: 12px 8px;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #ffffffe5;

  transition-timing-function: ease-in;
  transition: 0.2s;

  background-color: transparent;

  outline: 0;
  outline-offset: 0;

  border-radius: 4px;
  border: 1px solid #d3caff40;
}
.Input-styled::placeholder {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #d3caff73;
}
.Input-styled:focus {
  border-color: #656bff;
}
.Input-styled.Input-highligt-error {
  border-color: #ed254e;
}
.Input-styled.Input-highligt-error:focus + .Input-Placeholder,
.Input-styled.Input-highligt-error + .Input-Placeholder {
  color: #ed254e;
}

.Input-Placeholder {
  position: absolute;
  display: block;

  top: 0;
  left: 18px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;

  transform: translate(0px, -50%);

  transition-timing-function: ease-in;
  transition: 0.2s;

  color: #d3caff4d;
  background: #1e1b4a;
  padding: 0% 2px;
}
.Input-styled:focus + .Input-Placeholder {
  color: #656bff;
}

.Offer-Modal-Buttons {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;

  width: 320px;
  height: fit-content;
}

.Offer-Button-Next {
  position: relative;
  display: block;
  cursor: pointer;

  padding: 12px 16px;

  width: 100%;
  height: fit-content;

  flex-shrink: 1;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #ffffffe5;

  transition-timing-function: ease-in;
  transition: 0.2s;

  border-radius: 4px;
  background-color: #656bff;
}
.Offer-Button-Prev {
  position: relative;
  display: block;
  cursor: pointer;

  padding: 12px 16px;

  width: fit-content;
  height: fit-content;

  flex-shrink: 0;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #ffffffe5;

  transition-timing-function: ease-in;
  transition: 0.2s;

  border-radius: 4px;
  background-color: #656bff40;
}
.Offer-Button-Prev:hover {
  background-color: rgba(101, 106, 255, 0.144);
}

.Offer-Text-Before-Confirm {
  position: relative;
  display: block;

  width: 320px;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

  color: #d3caffb2;
}
.Offer-Text-Before-Confirm .Text-Before-Confirm-Important {
  color: #ffffffe5;
}

.Offer-Modal-Buttons-Child-Wrapper {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;

  width: 320px;
  height: fit-content;
}

.Offer-Text-Balance-After {
  position: relative;
  display: block;

  width: 320px;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: uppercase;

  color: #d3caff4d;
}
.Offer-Text-Error {
  position: relative;
  display: block;

  margin-top: 12px;

  width: 320px;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: none;
  color: #ed254e;
}
</style>

<style scoped>
@media (max-width: 480px) {
  .Products-Block {
    grid-template-columns: repeat(1, calc((100% - 24px * 0) / 1));
    padding-bottom: 100px;
  }

  .Modal-Card-Wrapper {
    padding-top: 20px;
    flex-direction: column;
  }

  .Modal-Card-Image {
    max-width: 100%;
    height: 150px;
  }

  .Styled-Modal:deep(.SendRequestModal) {
    margin: 20px auto auto auto;
    width: calc(100% - 40px);
  }

  .Product-Card.Modal-Card {
    width: 100%;
  }

  .Offer-Modal-Buttons {
    width: 100%;
  }

  .Offer-Button-Next,
  .Offer-Button-Prev {
    padding: 4px 8px;
    font-size: 13px;
  }

  .Offer-Modal-Title,
  .Offer-Modal-Content,
  .Input-Floating-Label,
  .Offer-Text-Before-Confirm,
  .Offer-Modal-Buttons-Child-Wrapper,
  .Offer-Text-Balance-After {
    width: 100%;
    min-width: 0;
  }
}
@media (min-width: 480px) and (max-width: 768px) {
  .Products-Block {
    grid-template-columns: repeat(2, calc((100% - 24px * 1) / 2));
  }

  .Modal-Card-Wrapper {
    padding-top: 20px;
    flex-direction: column;
  }

  .Modal-Card-Image {
    max-width: 100%;
    height: 200px;
  }

  .Styled-Modal:deep(.SendRequestModal) {
    margin: 50px auto auto auto;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .Products-Block {
    grid-template-columns: repeat(3, calc((100% - 24px * 2) / 3));
  }
}
@media (min-width: 992px) and (max-width: 1440px) {
  .Products-Block {
    grid-template-columns: repeat(4, calc((100% - 24px * 3) / 4));
  }
}
</style>
